@import '../partials/ProductIntro';
@import '../partials/ProductGallery';
@import '../partials/ProductPrice';
@import '../partials/ProductFAQ';
@import '../partials/ProductFeedback';

.page.ProductDetail {
	#fixedHeader {
		@media all and (min-width: $desktopMin) {
			right: 50%;
		}
	}
	section:last-child {
		padding-bottom: 60px;

		@media all and (min-width: $desktopMin) {
			padding-bottom: 0;
		}
	}
}

section.backToList {
	.w {
		padding-top: 20px;
		padding-bottom: 45px;
		color: $color_white;
		text-align: center;

		a {
			text-transform: uppercase;
			font-size: 11px;
			font-weight: bold;
			letter-spacing: 1px;
		}
	}
}
