section.productIntro {
	/*background: $color_lightOrange;*/
	padding-bottom: 0;
	padding-top: 20px;

	> .w {
		.img {
			margin: auto;

			&.squared {
				position: relative;
				padding-bottom: 100%;

				img {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
				}

				@media all and (min-width: $tabletMin) {
					max-width: 600px;
				}

				@media all and (min-width: $desktopMin) {
					max-width: 60vh;
					padding-bottom: 0;

					img {
						position: relative;
					}
				}
			}

			img {
				width: 100%;
				height: auto;
				margin: auto;

				@media all and (min-width: $tabletMin) {
					max-width: 600px;
				}

				@media all and (min-width: $desktopMin) {
					max-height: 60vh;
					max-width: none;
				}
			}
		}
	}

	.productHeading {
		background: $color_white;
		padding-top: 2vw;
		padding-bottom: 2vw;

		&.topCut {
			margin-top: 4.4vw;
		}

		.w {
			text-align: center;
			line-height: 1;
			padding-top: 0.625rem;
			padding-bottom: 0.625rem;

			p {
				padding-top: 0.5em;
				display: block;
			}

			.categoryName {
				display: block;
				padding-top: 0.8em;
				font-size: 0.625rem;
				text-transform: uppercase;
				font-weight: 900;
				letter-spacing: 0.05em;
			}

			.companyDescription {
				.outer {
					font-size: 0.9rem;
					text-align: left;
					margin-top: 2em;
					height: 6em;
					overflow: hidden;
					position: relative;
					.spacer {
						height: 6em;
						position: absolute;
					}
					.inner {
						p {
							padding-top: 0;
						}

						> * {
							margin-bottom: 1em;

							&:last-child {
								margin-bottom: 0;
							}
						}

						ul,
						ol {
							padding-left: 1em;
							li {
								display: list-item;
							}
						}
					}
				}

				.readMore,
				.readLess {
					display: none;
				}

				&.expandable {
					.outer:after {
						content: ' ';
						position: absolute;
						left: 0;
						right: 0;
						height: 2em;
						bottom: 0;
						background: rgb(255, 255, 255);
						background: -moz-linear-gradient(
							0deg,
							rgba(255, 255, 255, 1) 0%,
							rgba(255, 255, 255, 0) 100%
						);
						background: -webkit-linear-gradient(
							0deg,
							rgba(255, 255, 255, 1) 0%,
							rgba(255, 255, 255, 0) 100%
						);
						background: linear-gradient(
							0deg,
							rgba(255, 255, 255, 1) 0%,
							rgba(255, 255, 255, 0) 100%
						);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
						opacity: 1;
						transition: opacity 0.3s;
					}

					.readMore {
						display: block;
					}

					.readMore,
					.readLess {
						text-align: left;
						font-size: 0.8rem;
						color: $color_rainforest;
						text-decoration: underline;
						padding-top: 1em;
					}

					&.open {
						.outer {
							height: auto;
							&:after {
								opacity: 0;
							}
						}
						.readMore {
							display: none;
						}
						.readLess {
							display: block;
						}
					}
				}
			}

			.link {
				display: inline-block;
				margin-top: 2em;

				&.btn {
					font-size: 0.8rem;
					padding: 0.8em 1em;
					border-radius: 100px;
					background: $color_white;
					border: 1px solid $color_ink;
					color: $color_ink;
				}

				&.btnSmall {
					font-size: 0.6rem;
					padding: 0.8em 1em;
					border-radius: 100px;
					background: $color_lightOrange;
					text-transform: uppercase;
					border: 1px solid $color_ink;
				}

				&.inline {
					font-size: 0.8rem;
					text-decoration: underline;
				}
			}
		}
	}
}
