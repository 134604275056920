@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Playfair+Display:wght@500;700&display=swap');

body,
html {
	font-family: 'Lato';
	font-weight: 400;
	font-size: 16px;
	color: $color_ink;
	line-height: 1.33;

	@media all and (min-width: $tabletMin) {
		font-size: 18px;
	}
}

body input,
body textarea,
body button {
	font-family: 'Lato';
	font-weight: 400;
	font-size: 16px;
}

strong {
	font-weight: 700;
}

@mixin serif() {
	font-family: 'Playfair Display';
	font-weight: 500;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: normal;
}

body {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.serif {
		@include serif();
		color: $color_headings;
	}
}

h1 {
	font-size: 2.18rem;
}

h2 {
	font-size: 1.5rem;
}

.freeText {
	a {
		display: inline;
	}

	p {
		margin-bottom: 1em;
	}

	> *:last-child {
		margin-bottom: 0;
	}
}
