#navBar {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	background: $color_lightgray;
	padding: 10px 0;
	overflow: hidden;
	border-top: 0.2px solid $color_gray;

	@media all and (min-width: $desktopMin) {
		bottom: auto;
		left: 50%;
		top: 0;
		height: 70px;
		border-bottom: 0.2px solid $color_gray;
		border-left: 0.2px solid $color_gray;
		padding: 0;
		display: flex;
		align-items: center;
		border-top: none;
	}

	.items {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 ($mobileWPadding) + px;
		margin-bottom: env(safe-area-inset-bottom);

		@media all and (min-width: $tabletMin) {
			padding: 0 ($tabletWPadding) + px;
		}

		.item {
			flex-basis: 60px;
			display: flex;
			flex-direction: column;
			position: relative;
			color: $color_ink;
			cursor: pointer;

			&:before {
				content: ' ';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: -50px;
				bottom: -50px;
				background: $color_rainforest;
				opacity: 0;
				transition: opacity 0.3s;
			}

			&.selected:not(.disabled) {
				/*
				&:before {
					opacity: 0.2;
				}
				*/
				color: $color_rainforest;
				svg {
					.fill {
						fill: $color_rainforest !important;
					}
					.stroke {
						stroke: $color_rainforest !important;
					}
				}
			}

			&.disabled {
				opacity: 0.3;
				pointer-events: none;
			}

			img,
			svg {
				height: 24px;
				width: auto;
				position: relative;

				.fill,
				.stroke {
					transition: all 0.3s;
				}

				.fill {
					fill: $color_ink !important;
				}
				.stroke {
					stroke: $color_ink !important;
				}
			}

			span {
				display: block;
				padding-top: 5px;
				text-align: center;
				font-size: 9px;
				text-transform: uppercase;
				font-weight: bold;
				position: relative;
				transition: color 0.3s;
			}
		}
	}
}
