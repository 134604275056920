section.productFAQ {
	.w {
		padding-bottom: 1.38rem;
	}

	.question-block {
		border-top: 1px solid $color_gray;
		padding-top: 1em;
		margin-bottom: 1em;

		.question {
			color: $color_headings;
			display: flex;
			align-items: flex-start;
			cursor: pointer;

			> span {
				display: block;
				flex-grow: 1;
				padding-right: 20px;
			}

			.icon {
				width: 10px;
				height: 10px;
				flex-grow: 0;
				flex-shrink: 0;
				position: relative;
				margin-top: 0.4em;
				transition: transform 0.4s;

				span {
					position: absolute;
					width: 100%;
					left: 50%;
					top: 50%;
					height: 1px;
					background: $color_headings;
					transform: translate(-50%, -50%);

					&:nth-child(2) {
						transform: translate(-50%, -50%) rotate(-90deg);
					}
				}
			}
		}
		.expander {
			height: 0px;
			overflow: hidden;

			span {
				display: block;
				padding-top: 0.8em;
				padding-bottom: 0.1em;
				padding-left: 1em;
			}
		}

		&.open {
			.question {
				.icon {
					transform: rotate(-45deg) scale(1.1);
				}
			}
		}
	}
}
