#fixedHeader {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 70px;
	background: $color_lightgray;
	overflow: hidden;
	border-bottom: 0.2px solid $color_gray;
	z-index: 50;

	.w {
		max-width: none;
	}
	.contents {
		display: flex;

		.col {
			flex-grow: 0;
			position: relative;

			.icon {
			}

			&.left,
			&.right {
				display: flex;
				align-items: center;
				width: 32px;

				img {
					height: 24px;
				}
			}

			&.left {
				justify-content: flex-start;
			}
			&.right {
				justify-content: flex-end;
			}

			.icon {
				&.pause {
					opacity: 0;
					transform: scale(0);
					position: relative;

					img {
						height: 20px;
					}

					.circle {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 32px;
						height: 32px;
						circle {
							transform-origin: 50% 50%;
							transform: rotate(-90deg);
						}
					}
				}
			}

			&.center {
				flex-grow: 1;
				.img {
					padding: 10px 0;
					position: relative;

					img {
						max-height: 50px;
						max-width: 100px;
						width: auto;
						display: block;
						margin: auto;
					}

					img.partnerLogo {
						position: absolute;
						top: 50%;
						left: 50%;
						opacity: 0;
					}
				}
				span {
					@include absSpread();
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0;
					transform: translateY(100%);
					text-align: center;
					font-size: 11px;
					text-transform: uppercase;
					font-weight: bold;
					letter-spacing: 1px;
					padding: 0 20px;

					@media all and (min-width: $desktopMin) {
						@include serif();
						font-size: 1rem;
						text-align: left;
						color: $color_headings;
					}
				}
			}
		}
	}
}
