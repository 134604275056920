section.userLogin {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-top: 0;

	@media all and (min-width: $desktopMin) {
		flex-direction: row;

		.w {
			max-width: none !important;
		}
	}

	.intro {
		position: relative;
		overflow: hidden;
		flex-grow: 1;
		flex-shrink: 0;
		background: $color_ink;

		.video {
			opacity: 0.6;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 100%;
			width: 9999px;
			pointer-events: none;

			iframe {
				@include absSpread();
				width: 100%;
				height: 100%;

				/* se il video non è 16/9 */
				transform: scale(1.4);
			}
		}

		.heading {
			padding: 20px 0 40px;
			color: $color_white;

			@media all and (min-width: $desktopMin) {
				padding-top: 50px;
			}

			> * {
				transition: all 1s;
			}

			img {
				height: 60px;
			}
			h1 {
				font-size: 1.9rem;
				padding-top: 0.5em;
				color: $color_white;
			}
			p {
				margin-top: 1em;
				max-width: 100%;
			}
		}
	}

	.bottomContents {
		@media all and (min-width: $desktopMin) {
			min-width: 360px;

			&:before {
				display: none;
			}
			.loginForm {
				padding-top: 40px;
			}
		}
		.loginForm {
			position: relative;
			display: flex;
			flex-direction: column;

			h2 {
				margin-top: 0.5em;
				margin-bottom: 0.5em;
			}

			.contents {
				flex-grow: 1;
			}
		}
	}
}
