section.productSearchIntro {
	background: $color_rainforest;
	padding: 40px 0 50px;
	text-align: center;
	h1,
	p {
		color: $color_white;
	}

	h1 {
		margin-bottom: 0.1em;
	}
	&:after,
	&:before {
		content: ' ';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 30%;

		background: -moz-linear-gradient(
			0deg,
			rgba(0, 0, 0, 1) 0%,
			rgba(0, 0, 0, 0) 100%
		);
		background: -webkit-linear-gradient(
			0deg,
			rgba(0, 0, 0, 1) 0%,
			rgba(0, 0, 0, 0) 100%
		);
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 1) 0%,
			rgba(0, 0, 0, 0) 100%
		);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
		opacity: 0.1;
	}

	&:before {
		top: 0;
		bottom: auto;
		transform: rotate(180deg);
	}
}

section.productSearch {
	.w {
		padding-bottom: 0.625rem;

		h2,
		h2 + p {
			margin-bottom: 0.5em;
			@media all and (min-width: 400px) {
				text-align: center;
			}
		}

		form {
			max-width: 400px;
			margin: auto;

			input[type='number'] {
				font-size: 50px;
				background: $color_lightgray;
				border-radius: 5px;
				padding: 0 0.3em;
				text-align: center;
				border: 1px solid $color_gray;
			}

			.form-last {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
}

section.productsList {
	margin-top: 0;
	padding-top: 3vw;
	.w {
		h2,
		h2 + p {
			margin-bottom: 0.5em;
			@media all and (min-width: 400px) {
				text-align: center;
			}
		}

		ul {
			padding-top: 15px;
			padding-bottom: 15px;

			li {
				padding-top: 15px;
				padding-bottom: 0.93rem;

				a {
					display: flex;

					&:visited {
						.code {
							background-color: $color_rainforest;
						}
					}

					.code {
						font-size: 16px;
						display: flex;
						width: 40px;
						height: 40px;
						flex-shrink: 0;
						align-items: center;
						justify-content: center;
						background: $color_orange;
						border-radius: 100%;
						margin-right: 10px;
						color: $color_white;
						position: relative;
					}

					.texts {
						line-height: 1;

						.productName {
							text-transform: uppercase;
							letter-spacing: 0.05em;
							display: block;
							color: $color_headings;
							font-weight: bold;
							font-size: 14px;
							line-height: 1.33;
							padding-top: 0.2em;
						}

						.categoryName {
							font-size: 10px;
							text-transform: uppercase;
							font-weight: 900;
							letter-spacing: 0.05em;
						}
					}
				}
			}
		}
	}
}
