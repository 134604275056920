/* colors */
$color_white: #ffffff;
$color_ink: #4b4b4b;
$color_headings: #010101;

$color_gray: #a8a8a8;
$color_lightgray: #fafafa;
$color_rainforest: #006c62;
$color_red: #e30613;

$color_orange: #ec5443;
$color_lightOrange: #fbf5f4;

$color_blue: #3e63bb;

/* spacing */
$mobileWPadding: 20;
$tabletWPadding: 40;

/* breakpoints */
$tabletMin: 768px;
$desktopMin: 1024px;
