form {
	.form-group {
		position: relative;
		margin-bottom: 1em;
		padding-top: 0.5em;

		&:last-child {
			margin-bottom: 0;
		}

		label {
			position: absolute;
			left: 0;
			top: 1em;
			line-height: 1;
			color: $color_gray;
			pointer-events: none;
			transition: all 0.3s $easeOutQuart;
		}

		input[type='text'],
		input[type='password'],
		input[type='number'],
		input[type='email'] {
			background: transparent;
			border-bottom: 1px solid $color_gray;
			padding: 0.5em 0;
			font-size: 1rem;
			width: 100%;
			line-height: 1;
			border-radius: 0;
		}

		&.compiled {
			label {
				font-size: 0.7em;
				top: 0;
			}
		}
	}

	.form-last {
		display: flex;
		padding: 10px 0 20px;
		align-items: center;

		.alert {
			> strong {
				display: block;
			}
			padding-right: 20px;
			flex-grow: 1;
			font-size: 0.8em;
			color: $color_red;
		}

		button {
			margin-left: auto;
			color: $color_white;
			background: $color_rainforest;
			border-radius: 20px;
			height: 40px;
			padding: 0 20px;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 0.7em;
			letter-spacing: 0.07em;
			transition: all 0.3s;
			cursor: pointer;

			&:disabled {
				opacity: 0.4;
			}
		}
	}
}
