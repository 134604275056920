/*
:root {
	--real100vh: 100vh;
	--realDynamic100vh: 100vh;
}
*/

#root {
	height: 100%;
}

body,
html {
	-webkit-text-size-adjust: 100%;
	background-color: $color_white;
	height: 100%;
}

body,
body * {
	box-sizing: border-box;
	border: none;
	margin: 0;
	padding: 0;
	outline: none;
	color: inherit;
	text-decoration: none;

	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;

	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;

	&:not(input) {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
		user-select: none;
	}
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;

	-webkit-appearance: none;
}

body {
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-animation: autofill 0s forwards;
	animation: autofill 0s forwards;
}

div,
ul,
li,
img {
	display: block;
}

label {
	cursor: inherit;
}

.relative {
	position: relative;
}

.w {
	width: 100%;
	position: relative;
	margin: auto;
	padding: 0 ($mobileWPadding) + px;

	@media all and (min-width: $tabletMin) {
		padding: 0 ($tabletWPadding) + px;
	}

	@media all and (min-width: $desktopMin) {
		max-width: 1024px;
	}
}

.cf,
.clear {
	clear: both;
}

.icf:after,
.w:after {
	content: ' ';
	display: block;
	clear: both;
}

.w .innerW {
	position: relative;
}

.FH {
	min-height: 100vh;
	/*min-height: var(--realDynamic100vh);*/
}

.page {
	position: relative;
	height: 100%;

	&.ProductDetail > section:first-child,
	#fixedHeader + section,
	#navBar + section {
		margin-top: 70px;
		border-top: none;
	}

	section:first-child {
		border-top: none;
	}

	/*
	&.ProductDetail > section {
		padding: 20px 0;
	}
*/
	section {
		&[data-sectionname] {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: center;

			&.productFeedback {
			}
		}

		scroll-snap-align: start;

		position: relative;

		border-top: 0.2px solid $color_gray;

		h2.sectionTitle {
			text-align: center;
			margin-bottom: 1em !important;

			&:before,
			&:after {
				content: ' ';
				display: inline-block;
				height: 1px;
				width: 30px;
				background: $color_headings;
				vertical-align: middle;
			}

			&:before {
				margin-right: 0.5em;
			}
			&:after {
				margin-left: 0.5em;
			}
		}

		p {
			a {
				color: $color_rainforest;
			}

			&.center {
				text-align: center;
			}
		}
	}
}

.page .topCut,
.page section.topCut {
	position: relative;
	border-top: none;
	&:before {
		content: ' ';
		position: absolute;
		left: -10vw;
		right: 0;
		height: 6vw;
		top: 0;
		background: $color_white;
		transform-origin: right top;
		transform: rotate(2deg);
	}
}

.page section.topCut {
	margin-top: 4.4vw;
	padding-bottom: 2vw;
	padding-top: 2vw;

	@media all and (min-width: $tabletMin) {
		padding-top: 3vw;
		padding-bottom: 3vw;
	}
}

.page section.bgLightOrange {
	&.topCut {
		&:before {
			background: $color_lightOrange;
		}
	}
	background: $color_lightOrange;

	&:after {
		content: ' ';
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		height: 4.4vw;
		background: $color_lightOrange;
	}
}

.page section.bgRainforest {
	&.topCut {
		&:before {
			background: $color_rainforest;
		}
	}
	background: $color_rainforest;
}

@media all and (min-width: $desktopMin) {
	.page {
		scroll-snap-type: none;
		scroll-padding: 0;

		section {
			scroll-snap-align: none;
		}
	}
}
