section.productGallery {
	.video {
		margin-top: 16px;
	}

	ul.masonryGallery {
		margin: 16px -8px -16px;
		width: calc(100% + 16px);

		li {
			width: 50%;
			margin-bottom: 16px;

			@media all and (min-width: $desktopMin) {
				width: calc(100% / 3);
			}

			div {
				padding-right: 8px;
				padding-left: 8px;
			}
			img {
				width: 100%;
				cursor: pointer;
			}
		}
	}
}
