section.landing {
	padding: 50px 0;
	text-align: center;

	img {
		width: 120px;
		margin: 0 auto 30px;
	}

	h2 {
		margin-bottom: 20px;
	}

	.btn {
		margin-top: 20px;

		color: $color_white;
		background: $color_rainforest;
		border: 1px solid $color_rainforest;
		border-radius: 20px;
		height: 40px;
		padding: 0 20px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 0.7em;
		letter-spacing: 0.07em;
		transition: all 0.3s;
		align-items: center;
		cursor: pointer;

		display: inline-flex;
	}
}
