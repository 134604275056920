section.productPrice {
	.table {
		&:last-child {
			padding-bottom: 0.625rem;
		}

		@media all and (min-width: $desktopMin) {
			.row.field {
				&:nth-child(odd) {
					background: #f4f2f2;
				}
				&:nth-child(even) {
					background: #fcfbfb;
				}

				padding-left: 0.5em;
				padding-right: 0.5em;
			}
		}

		.row {
			display: flex;
			padding-bottom: 0.35em;
			padding-top: 0.35em;

			&.heading {
				position: relative;
				padding-bottom: 0.5em;
				margin-bottom: 1em;

				&:after {
					content: ' ';
					display: block;
					width: 50px;
					background: $color_gray;
					height: 1px;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}

			.col {
				padding-right: 15px;

				&:nth-child(1) {
					flex-grow: 1;
					padding-top: 0.2em;
				}

				&:last-child {
					padding-right: 0;
				}

				&:first-child:last-child {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				&.input,
				&.output {
					display: flex;
					align-items: center;

					.post,
					.pre {
						display: block;
						white-space: nowrap;
						font-size: 11px;
					}

					.pre {
						padding-right: 0.5em;
					}

					.post {
						padding-left: 0.5em;
					}

					.form-group {
						background: $color_white;
						border: 1px solid $color_gray;
						border-radius: 0.5em;
						padding: 3px 0;
						width: 3em;

						input {
							width: 100%;
							background: transparent;
							text-align: center;
						}
					}

					&.output {
						line-height: 1;

						.value {
							white-space: nowrap;
							text-align: right;
						}
					}
				}

				span {
					font-size: 0.62rem;
					text-transform: uppercase;
					letter-spacing: 1px;
					font-weight: bold;
					display: block;
					padding-bottom: 0.8em;
				}

				.switch {
					position: relative;
					display: flex;
					border-radius: 20px;
					background: $color_gray;

					.selector {
						position: absolute;
						top: 0.2em;
						left: 0;
						margin-left: 0.2em;
						width: calc(50% - 0.4em);
						bottom: 0.2em;
						background: $color_white;
						border-radius: 20px;
					}

					.choice {
						font-size: 12px;
						text-transform: uppercase;
						font-weight: bold;
						padding: 0.55em 0 0.5em;
						position: relative;
						width: 4em;
						text-align: center;
					}
				}

				h3 {
					margin-top: 0.9em;
					padding-bottom: 0.4em;
				}

				h3 + p {
					display: block;
					padding: 0.1em 0 0.5em;
				}
			}
		}
	}
}
