section.productFeedback {
	.w {
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
	}
	p {
		text-align: center;
	}
	.ctas {
		padding: 30px 0 0 0;
		display: flex;
		align-items: center;
		flex-direction: column;

		.btn {
			margin-top: 20px;

			color: $color_rainforest;
			background: $color_white;
			border: 1px solid $color_rainforest;
			border-radius: 20px;
			height: 40px;
			padding: 0 20px;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 0.7em;
			letter-spacing: 0.07em;
			transition: all 0.3s;
			display: flex;
			align-items: center;
			cursor: pointer;

			&:first-child {
				margin-top: 0;
			}
		}
	}
}

section.overlayIframe {
	border-top: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background: $color_white;
	z-index: 50;
	opacity: 0;
	display: none;

	.inner {
		@include absSpread();
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		padding-bottom: 20px;

		.close {
			margin-right: 20px;
			margin-top: 20px;
			margin-bottom: 20px;
			width: 26px;
			height: 26px;
			border-radius: 100%;
			border: 1px solid $color_rainforest;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 20px;
				height: auto;
			}
		}
		iframe {
			width: 100%;
			flex-grow: 1;
		}
	}
}
